import React from 'react'
import Scrollbar from 'react-scrollbars-custom'

import { ProductCard } from 'src/components/product/card'

export interface ProductRowProps {
  data: {
    products: any[]
    title: string
    shortDescription?: string
  }
}

export const ProductRow = ({ data }: ProductRowProps) => {
  const {
    products,
    title,
    shortDescription
  } = data
  return (
    <div className='mt2 mb2 of--hidden'>
      <div className='x p2 tc'>
        <h2 className='h1 sans s60--1000 pb0' dangerouslySetInnerHTML={{__html: title }} />
        {shortDescription && (
          <p className='caps mt0 s18'>{shortDescription}</p>
        )}
      </div>
      <div className='bt bc mt1--600'>
        <div className='hide--800'>
          <div className='ws--nowrap  df row product__rows ofy--scroll bb bc'>
            {products && products.map((singleProduct, i) => (
              <div className='col product__card-col bl bc c6 c4--800' key={`${singleProduct._id}${i}`}>
                <ProductCard {...singleProduct} />
              </div>
            ))}
          </div>
        </div>
        <div className='show--800'>
          <Scrollbar style={{ width: '100%', height: 570 }} noScrollY={true}>
            <div className='ws--nowrap  df row product__rows'>
              {products && products.map((singleProduct, i) => (
                <div className='col product__card-col bl bc c6 c4--800' key={`${singleProduct._id}${i}`}>
                  <ProductCard {...singleProduct} />
                </div>
              ))}
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  )
}

export default ProductRow